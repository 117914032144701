
import { defineComponent, reactive, ref, computed, watch, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AppInput from '@/components/atoms/AppInput.vue'
import AppSelect from '@/components/atoms/AppSelect.vue'
import AppCheckbox from '@/components/atoms/AppCheckbox.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import CheckboxList from '@/components/molecules/CheckboxList.vue'
import { useI18n } from 'vue-i18n'
import InputDate from '@/components/molecules/InputDate.vue'
import { eraList } from '@/data/selector/eraList'
import { availableTypeList } from '@/data/selector/availableTypeList'
import { identifierList } from '@/data/selector/identifierList'
import TextAlert from '@/components/molecules/TextAlert.vue'
import compareObjectRecursive from '@/helpers/util/compareObjectRecursive'
import CloserController from '@/components/molecules/CloserController.vue'
import StringKeyObject from '@/data/@types/StringKeyObject'
import TextWithLeadingIcon from '@/components/molecules/TextWithLeadingIcon.vue'
import ModalSheetMobile from '@/components/organisms/ModalSheetMobile.vue'
import { getSelector } from '@/data/selector/getSelector'
import DetailedSearchFooter from '@/components/molecules/DetailedSearchFooter.vue'

export default defineComponent({
  components: {
    AppInput,
    AppSelect,
    AppCheckbox,
    ButtonIcon,
    CheckboxList,
    InputDate,
    TextAlert,
    CloserController,
    TextWithLeadingIcon,
    ModalSheetMobile,
    DetailedSearchFooter,
  },
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale
    const initialCollection = [...store.getters.CollectionValueList]

    const accessRestrictions = [
      { value: 'internet', label: i18n.t('detailSearch.accessRestriction.internet') },
      { value: 'ooc', label: i18n.t('detailSearch.accessRestriction.ooc') },
      { value: 'inlibrary', label: i18n.t('detailSearch.accessRestriction.inlibrary') },
    ]

    /*
    フロントのコンポーネント
    詳細検索画面の再描画時に必要なクエリストリングを格納する
    Requestに必要なパラメータはDetailedSearchFromPageで作成・格納する

    PI4時点のパラメータ
    accessRestrictions: 閲覧できる場所
    collection: コレクション
    keyword: キーワード
    title: タイトル
    tableOfContents: 目次
    creator: 著者・編者
    publicationPlace: 出版地
    publisher: 出版者
    eraType: 時代
    publicationFrom: 開始年月日
    publicationTo: 終了年月日
    subject: 件名
    ndc: ndc分類
    ndlc: ndlc分類
    callNumber: 請求記号
    identifierItem: 識別番号区分
    identifier: 識別番号
    includeVolumeNum: 巻号を含める（階層）
    */
    const initialQuery = {
      accessRestrictions: store.getters.getAccessRestrictionsCheckList,
      collection: initialCollection,
      keyword: '',
      fullText: true,
      title: '',
      tableOfContents: '',
      creator: '',
      publicationPlace: '',
      publisher: '',
      eraType: 'AD',
      availableType: 'Persend',
      availableFrom: '',
      availableTo: '',
      publicationFrom: '',
      publicationTo: '',
      subject: '',
      ndc: '',
      ndlc: '',
      callNumber: '',
      identifierItem: 'ISBN',
      identifier: '',
      includeVolumeNum: true,
    }
    const setupQuery = {
      ...initialQuery,
      ...route.query,
      collection: route.query.collection ? (typeof route.query.collection === 'string' ? [route.query.collection] : route.query.collection) : initialQuery.collection,
      fullText: route.query.fullText ? route.query.fullText === 'true' : initialQuery.fullText,
      includeVolumeNum: route.query.includeVolumeNum ? route.query.includeVolumeNum === 'true' : initialQuery.includeVolumeNum,
    }

    const query = reactive(setupQuery)
    if (route.name === 'DetailedSearch') {
      query.keyword = store.getters.getInputKeyword
    }

    // 公開範囲指定なし（accessRestrictions=）のクエリで遷移した場合の対処
    if (!query.accessRestrictions) {
      query.accessRestrictions = []
    } else if (typeof query.accessRestrictions === 'string') {
      query.accessRestrictions = [query.accessRestrictions]
    }

    const hasErrorCollection = ref<boolean>(false)

    const validate = () => {
      hasErrorCollection.value = !query.collection.length
    }
    const state = reactive({
      modalNdc: false,
      showAll: store.getters.openClosure,
      showModal: false,
    })

    const search = async () => {
      // 検索中に再度検索が呼び出された場合、実行しない
      const isSearching = store.getters.itemIsProcessing
      if (isSearching) return

      validate()
      if (hasErrorCollection.value) {
        return
      }
      const searchMode = computed(() => store.getters.searchMode)
      // 公開範囲：チェック状態保持
      store.commit('SET_ACCESS_RESTRICTIONS', query.accessRestrictions)
      query.keyword = query.keyword.trim()

      const where: StringKeyObject = {
        ...query,
      }

      for (var propName in where) {
        if (where[propName] === null || where[propName] === undefined || where[propName] === '') {
          delete where[propName]
        }
      }
      if (where.publicationFrom === '0000-00-00') delete where.publicationFrom
      if (where.publicationTo === '0000-00-00') delete where.publicationTo
      if (where.availableFrom === '0000-00-00') delete where.availableFrom
      if (where.availableTo === '0000-00-00') delete where.availableTo

      // 検索時にはファセットを初期化する
      delete where.permission_facet
      delete where.collection_facet
      delete where.ndc_facet
      delete where.levelCode_facet
      delete where.itemToSearch_facet
      delete where.fromYear_facet
      delete where.toYear_facet

      // 検索実行したらモーダルを閉じる
      state.showModal = false

      where.pageNum = '0' // 再検索した場合は必ず先頭ページ
      // ページ情報をセッションから取得
      where.pageSize = searchMode.value.pageSize || '20'
      where.sortKey = searchMode.value.sortKey || 'SCORE'
      where.displayMode = searchMode.value.displayMode || 'list'

      const routeQuery: StringKeyObject = { ...route.query }
      if (route.query.fullText) routeQuery.fullText = route.query.fullText === 'true'
      if (route.query.includeVolumeNum) routeQuery.includeVolumeNum = route.query.includeVolumeNum === 'true'
      if (route.query.collection) routeQuery.collection = typeof route.query.collection === 'string' ? [route.query.collection] : route.query.collection
      if (route.query.accessRestrictions) routeQuery.accessRestrictions = typeof route.query.accessRestrictions === 'string' ? [route.query.accessRestrictions] : route.query.accessRestrictions

      console.log('search query: ', where, routeQuery)
      if (compareObjectRecursive(where, routeQuery)) {
        console.log('FORCE SEARCH')
        emit('search')
      } else {
        console.log('PUSH FOR SEARCH')
        router.push({
          name: 'DetailedSearchResult',
          query: where,
        })
      }
    }

    const publicationResetKey = ref<number>(0)
    const clearForm = () => {
      query.collection = initialCollection
      query.keyword = ''
      query.fullText = true
      query.title = ''
      query.tableOfContents = ''
      query.creator = ''
      query.publicationPlace = ''
      query.publisher = ''
      query.eraType = 'AD'
      query.availableType = 'Persend'
      query.availableFrom = ''
      query.availableTo = ''
      query.publicationFrom = ''
      query.publicationTo = ''
      publicationResetKey.value++ // コンポーネントのKeyを更新して再描画させる

      query.subject = ''
      query.ndc = ''
      query.ndlc = ''
      query.callNumber = ''
      query.identifierItem = 'ISBN'
      query.identifier = ''
      query.includeVolumeNum = true
    }
    const eraListLocale = computed(() => getSelector('eraList', eraList, i18n.t))
    const availableTypeListLocale = computed(() => getSelector('availableTypeList', availableTypeList, i18n.t))
    const identifierListLocale = computed(() => getSelector('identifierList', identifierList, i18n.t))

    // 遷移後最初の状態を保持しておくため
    const firstAccessRestriction = store.getters.getAccessRestrictionsCheckList

    // routeを監視して変化があればクエリの値で更新する
    watch(route, () => {
      query.accessRestrictions = route.query.accessRestrictions ? typeof route.query.accessRestrictions === 'string' ? [route.query.accessRestrictions] : route.query.accessRestrictions : firstAccessRestriction
      query.collection = route.query.collection ? typeof route.query.collection === 'string' ? [route.query.collection] : route.query.collection : store.getters.CollectionValueList
      query.keyword = route.query.keyword ? route.query.keyword.toString() : ''
      query.fullText = route.query.fullText ? route.query.fullText.toString() === 'true' : true
      query.title = route.query.title ? route.query.title.toString() : ''
      query.tableOfContents = route.query.tableOfContents ? route.query.tableOfContents.toString() : ''
      query.creator = route.query.creator ? route.query.creator.toString() : ''
      query.publicationPlace = route.query.publicationPlace ? route.query.publicationPlace.toString() : ''
      query.publisher = route.query.publisher ? route.query.publisher.toString() : ''
      query.eraType = route.query.eraType ? route.query.eraType.toString() : 'AD'
      query.publicationFrom = route.query.publicationFrom ? route.query.publicationFrom.toString() : ''
      query.publicationTo = route.query.publicationTo ? route.query.publicationTo.toString() : ''
      query.availableType = route.query.availableType ? route.query.availableType.toString() : 'Persend'
      query.availableFrom = route.query.availableFrom ? route.query.availableFrom.toString() : ''
      query.availableTo = route.query.availableTo ? route.query.availableTo.toString() : ''
      query.subject = route.query.subject ? route.query.subject.toString() : ''
      query.ndc = route.query.ndc ? route.query.ndc.toString() : ''
      query.ndlc = route.query.ndlc ? route.query.ndlc.toString() : ''
      query.callNumber = route.query.callNumber ? route.query.callNumber.toString() : ''
      query.identifierItem = route.query.identifierItem ? route.query.identifierItem.toString() : 'ISBN'
      query.identifier = route.query.identifier ? route.query.identifier.toString() : ''
      query.includeVolumeNum = route.query.includeVolumeNum ? route.query.includeVolumeNum.toString() === 'true' : true
      publicationResetKey.value++ // コンポーネントのKeyを更新して再描画させる
    })

    const isServer = typeof window === 'undefined'
    onMounted(() => {
      if (isServer) return
      const headerSiteName = document.getElementById('the-global-header-site-name') as HTMLElement
      headerSiteName.focus()
    })

    return {
      collectionCheckboxItem: store.getters.CollectionCheckboxItem,
      search,
      query,
      clearForm,
      hasErrorCollection,
      eraListLocale,
      availableTypeListLocale,
      identifierListLocale,
      lang,
      accessRestrictions,
      state,
      publicationResetKey,
    }
  },
})
